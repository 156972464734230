<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
                destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="基本信息"></x-divider>

            <x-selector
                    v-show="form.id==null"
                    placement="center"
                    selector="ShangPinXXAllGuiGeSelector"
                    @selected="handleClSelect"
                    :param="{'shangPinLaiYuan': '商户同步'}"
                    code-key="id"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round>添加商品</el-button>
            </x-selector>
            <x-row :rows="form.diaoBoDanXiangQings" :rowModel="diaoBoDanXiangQingsModel"  no-add-btn>

                <x-row-cell  read="shangPinMC" title="商品名称"/>
                <x-row-cell title="申请数量" width="100">
                    <el-input slot-scope="{row}" v-model="row.shenQingSL" size="small" maxlength="8"/>
                </x-row-cell>
                <x-row-cell  read="guiGe" title="规格"/>
                <x-row-cell  read="huoHao" title="货号"/>
                <x-row-cell  read="tiaoMa" title="条码"/>
                <x-row-cell  read="danWei" title="单位"/>
                <x-row-cell title="备注" width="100">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="64"/>
                </x-row-cell>
            </x-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hlgl/DiaoBoDan";
/*    import DiaoBoDanEditBase from "@/view/hlgl/dbd/DiaoBoDanEditBase";*/
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {checkboxList} from "@/service/mdgl/MenDianXX";
    import ShangPinXXSelector from "@/view/selector/ShangPinXXSelector";
    import ShangPinXXAllGuiGeSelector from "@/view/selector/ShangPinXXAllGuiGeSelector";
    import XUserSelector from "@/components/x/selector/XUserSelector";
    export default {
        mixins: [XEditBase],
        components: {XUserSelector,ShangPinXXSelector,ShangPinXXAllGuiGeSelector},
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                diaoBoDanBH: [], // 调拨单编号
                caoZuoRen: [], // 操作人
                caoZuoRenID: [], // 操作人ID
                shenQingSJ: [], // 申请时间
                chuKuSJ: [], // 出库时间
                caoZuoSJ: [], // 操作时间
                shenQingHeJi: [], // 申请合计
                yingShouHeJi: [], // 应收合计
                yingShouJinE: [], // 应收金额
                shiShouHeJi: [], // 实收合计
                shiShouJinE: [], // 实收金额
                zhuangTai: [], // 状态
                juShouHeJi: [], // 拒收合计
                juShouJinE: [], // 拒收金额
                beiZhu: [], // 备注
                confirm: [], // 确认状态
            }
            this.titlePrefix = '调拨单';
            this.diaoBoDanXiangQingsModel = {
                diaoBoDanBH: "", // 调拨单编号
                shenQingSJ: "", // 申请时间
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                guiGe: "", // 规格
                huoHao: "", // 货号
                tiaoMa: "", // 条码
                danWei: "", // 单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                shenQingSL: "", // 申请数量
                chuKuSL: "", // 出库数量
                chuKuJinE: "", // 出库金额
                chuKuHeJi: "", // 出库合计
                ruKuSL: "", // 入库数量
                ruKuJinE: "", // 入库金额
                ruKuHeJi: "", // 入库合计
                beiZhu: "", // 备注
                beiYong: "", // 备用
                guiGeJiShu: "", // 规格基数
            }
            this.defaultForm = {
                id: null,
                diaoBoDanBH: "", // 调拨单编号
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                caoZuoRen: this.$store.getters.user.name, // 操作人
                shenQingSJ: "", // 申请时间
                chuKuSJ: "", // 出库时间
                caoZuoSJ: "", // 操作时间
                shenQingHeJi: "", // 申请合计
                yingShouHeJi: "", // 应收合计
                yingShouJinE: "", // 应收金额
                shiShouHeJi: "", // 实收合计
                shiShouJinE: "", // 实收金额
                zhuangTai: "", // 状态
                juShouHeJi: "", // 拒收合计
                juShouJinE: "", // 拒收金额
                beiZhu: "", // 备注
                confirm: "", // 确认状态
                diaoBoDanXiangQings: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },

        data() {
            return {
/*                options:[],
                spxxs:[],*/
            }
        },
        mounted() {
            this.getCangKuOptions();
        },
        methods: {
            handleClSelect(cls) {
                const diaoBoDanXiangQings = this.form.diaoBoDanXiangQings;
                let cc = [];
                this.form.diaoBoDanXiangQings.forEach(item => cc.push(item.shangPinID));
                cls.forEach(cl => {
                    let obj = {};
                    if(!cc.includes(cl.shangHuShangPinId)) {
                        obj.shangPinID = cl.shangHuShangPinId || ''
                        obj.danWei = cl.shangPinDW || ''
                        obj.shangPinMC = cl.shangPinMC || ''
                        obj.guiGe = cl.ciJiGuiGeMC || ''
                        if(cl.shiFuDuoGuiGe === 'true'){
                            obj.huoHao = cl.guiGeHuoHao || '';
                            obj.tiaoMa = cl.guiGeTiaoMa || '';
                        }else {
                            obj.huoHao = cl.shangPinHuoHao || '';
                            obj.tiaoMa = cl.shangPinTiaoMa || '';
                        }
                        obj.shiFuDuoGuiGe = cl.shiFuDuoGuiGe || ''
                        obj.guiGeShangPinJinJia = cl.guiGeShangPinJinJia || ''
                        obj.shangPinJinJia = cl.shangPinJinJia || ''
                        diaoBoDanXiangQings.push(obj);
                    }
                });
            },
            //获取门店列表
            getCangKuOptions() {
                checkboxList().then((response) => {
                    this.options = response.data
                })
            },
            //获取商品信息列表
            change(cangKuMC){
                getListByCangKuMC(cangKuMC).then((response) => {
                    this.spxxs = response.data
                })
            }

        }
    }
</script>

<style scoped>

</style>