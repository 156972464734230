<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
                destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="diaoBoDanBH" label="调拨单编号" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
            <x-detail-item prop="shenQingSJ" label="申请时间" />
            <x-detail-item prop="chuKuSJ" label="出库时间" />
            <x-detail-item prop="caoZuoSJ" label="操作时间" />
            <x-detail-item prop="shenQingHeJi" label="申请合计" />
            <x-detail-item prop="yingShouHeJi" label="应收合计" />
            <x-detail-item prop="yingShouJinE" label="应收金额" />
            <x-detail-item prop="shiShouHeJi" label="实收合计" />
            <x-detail-item prop="shiShouJinE" label="实收金额" />
            <x-detail-item prop="zhuangTai" label="状态" >
                <x-dict-show :code="entity.zhuangTai" dictType="T_HUO_LIU_STATUS"/>
            </x-detail-item>
            <x-detail-item prop="jvShouHeJi" label="拒收合计" />
            <x-detail-item prop="jvShouJinE" label="拒收金额" />
            <x-detail-item prop="beiZhu" label="备注" />
        </x-detail>
        <x-divider title="调拨单详情"></x-divider>
        <el-table :data="entity.diaoBoDanXiangQings" border class="x-detail-table">
            <el-table-column width="60" label="序号" prop="sort" />
            <el-table-column width="150" prop="shenQingSJ" label="申请时间" />
            <el-table-column width="150" prop="shangPinMC" label="商品名称" />
            <el-table-column width="150" prop="shenQingSL" label="申请数量" />
            <el-table-column width="150" prop="chuKuSL" label="出库数量" />
            <el-table-column width="150" prop="ruKuSL" label="入库数量" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="huoHao" label="货号" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="danWei" label="单位" />
            <el-table-column width="150" prop="shengChanRQ" label="生产日期" />
            <el-table-column width="150" prop="baoZhiQiTian" label="保质期（天）" />
            <el-table-column width="150" prop="chuKuSL" label="出库数量" />
            <el-table-column width="150" prop="chuKuJia" label="出库价" />
            <el-table-column width="150" prop="chuKuJinE" label="出库金额" />
            <el-table-column width="150" prop="ruKuSL" label="入库数量" />
            <el-table-column width="150" prop="ruKuJia" label="入库价" />
            <el-table-column width="150" prop="ruKuJinE" label="入库金额" />
            <el-table-column width="150" prop="jvShouSL" label="拒收数量" />
            <el-table-column width="150" prop="jvShouJinE" label="拒收金额" />
            <el-table-column width="150" prop="beiZhu" label="备注" />
        </el-table>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hlgl/DiaoBoDan";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                diaoBoDanBH: "", // 调拨单编号
                caoZuoRen: "", // 操作人
                caoZuoRenID: "", // 操作人ID
                shenQingSJ: "", // 申请时间
                chuKuSJ: "", // 出库时间
                caoZuoSJ: "", // 操作时间
                shenQingHeJi: "", // 申请合计
                yingShouHeJi: "", // 应收合计
                yingShouJinE: "", // 应收金额
                shiShouHeJi: "", // 实收合计
                shiShouJinE: "", // 实收金额
                zhuangTai: "", // 状态
                juShouHeJi: "", // 拒收合计
                juShouJinE: "", // 拒收金额
                beiZhu: "", // 备注
                confirm: "", // 确认状态
                diaoBoDanXiangQings: [],
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>