import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/diao-bo-dan/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/diao-bo-dan/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/diao-bo-dan/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/diao-bo-dan/get-detail/${id}`,
        method: 'get',
    })
}
//获取带库存的编辑页数据
export function getChuKuUpdate(id) {
    return request({
        url: `/diao-bo-dan/get-chu-ku-update/${id}`,
        method: 'get',
    })
}
//编辑 和 出库
export function chuKuUpdate(data) {
    return request({
        url: '/diao-bo-dan/chu-ku-update',
        method: 'post',
        data
    })
}
//编辑 和 入库
export function ruKuUpdate(data) {
    return request({
        url: '/diao-bo-dan/ru-ku-update',
        method: 'post',
        data
    })
}

// 拒收方法
export function jvShou(id) {
    return request({
        url: `/diao-bo-dan/jv-shou/${id}`,
        method: 'post',
    })
}
// 拒收确认方法
export function jvShouRuKu(id) {
    return request({
        url: `/diao-bo-dan/jv-shou-ru-ku/${id}`,
        method: 'post',
    })
}


//编辑保存
export function update(data) {
    return request({
        url: '/diao-bo-dan/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/diao-bo-dan/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/diao-bo-dan/delete-batch',
        method: 'post',
        data
    })
}

//确认
export function confirm(id) {
    return request({
        url: `/diao-bo-dan/confirm/${id}`,
        method: 'post',
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/diao-bo-dan/query',
        method: 'post',
        data
    })
}

//选择元列表
export function selector(data) {
    return request({
        url: '/diao-bo-dan/selector',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'diao-bo-dan-list',
    component: () => import('@/view/hlgl/DiaoBoDanList'),
    name: 'DiaoBoDanList',
    meta: {title: '调拨单', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'diao-bo-dan-query',
    component: () => import('@/view/hlgl/DiaoBoDanQuery'),
    name: 'DiaoBoDanQuery',
    meta: {title: '调拨单查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'diao-bo-dan-remind-list',
    component: () => import('@/view/hlgl/DiaoBoDanRemindList'),
    name: 'DiaoBoDanRemindList',
    meta: {title: '调拨单提醒', isTag: true, isMenu: true, searchable: true}
}

选择元
{key:'DiaoBoDanSelector', value:'调拨单'}

菜单识别:基础相关-list为入口
[
{"name":"调拨单基础列表","methodUrl":"/diao-bo-dan/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"调拨单添加保存","methodUrl":"/diao-bo-dan/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"调拨单获取编辑数据","methodUrl":"/diao-bo-dan/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"调拨单获取详情数据","methodUrl":"/diao-bo-dan/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"调拨单编辑保存","methodUrl":"/diao-bo-dan/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"调拨单删除","methodUrl":"/diao-bo-dan/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"调拨单确认","methodUrl":"/diao-bo-dan/confirm/{id}","operateType":"edit","sort":8,"functionClassification":"0","_key":"8"},
{"name":"调拨单选择元列表","methodUrl":"/diao-bo-dan/selector","operateType":"search","sort":18,"functionClassification":"0","_key":"18"},
{"name":"调拨单批量删除","methodUrl":"/diao-bo-dan/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"调拨单独立查询","methodUrl":"/diao-bo-dan/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
