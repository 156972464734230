<template>
    <el-popover :disabled="disabled">
        <div style="width: 300px;">
            <el-input placeholder="输入关键字进行过滤" size="small" v-model="filterText" style="width:180px"></el-input>
            <el-button type="success" size="mini" icon="el-icon-check" @click="handleConfirm"
                       style="margin-left: 10px"></el-button>
            <el-button type="primary" size="mini" icon="el-icon-brush" @click="handleClear"></el-button>
        </div>
        <el-tree ref="tree" :data="treeData" :props="defaultProps"
                 node-key="id"
                 :default-expanded-keys="expanded"
                 :filter-node-method="filterNode"
                 show-checkbox
                 @check-change="handleCheckChange">
            <template slot-scope="{ node, data }">
                <span v-if="!data.user" class="el-icon-office-building">{{ data.name }}</span>
                <el-tooltip v-else effect="dark" :content="data.deptName + '-' + data.name" placement="top-start">
                    <span class="el-icon-user">{{ data.name }}</span>
                </el-tooltip>
            </template>
        </el-tree>
        <div slot="reference" style="position: relative">
            <el-input readonly :disabled="disabled" :type="multiple ? 'textarea' : 'text'" v-model="name" size="small"
                      ref="toggle"/>
            <i class="el-icon-user" style="position: absolute;right: 5px;top: 10px;"></i>
        </div>
    </el-popover>
</template>

<script>
import {userSelector} from '@/service/system/user';

export default {
    name: "XUserSelector",
    data() {
        this.defaultProps = {
            children: 'subNodes',
            label: 'name'
        };
        return {
            filterText: '',
            treeData: []
        };
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    computed: {
        expanded() {
            let checks = (this.value && this.value.split(",")) || [];
            this.$nextTick(() => {
                this.$refs.tree.setCheckedKeys(checks);
            });
            return checks;
        }
    },
    beforeMount() {
        userSelector().then((response) => {
            this.treeData = response.data;
        })
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        _selectUser() {
            let checks = this.$refs.tree.getCheckedNodes();
            let users = [];
            if (checks || checks.length > 0) {
                if (this.multiple) {
                    checks.forEach((obj) => {
                        if (obj.user) users.push(obj);
                    })
                } else {
                    checks.forEach((obj) => {
                        if (users.length === 0) {
                            if (obj.user) users.push(obj);
                        }
                    })
                }
            }
            this.$emit("update:name", users.map(obj => obj.name).join(this.separator));
            this.$emit("input", users.map(obj => obj.id).join(","));
            this.$emit("selected", users, this.selectedArgs);
        },
        handleCheckChange(data, checked, node) {
            //处理单选选中的情况
            if (!this.multiple && checked) {
                this.$refs.tree.setCheckedKeys([data.id]);
            }
            this._selectUser();
        },
        handleConfirm() {
            this.$refs.toggle.$el.click();
        },
        handleClear() {
            this.$refs.tree.setCheckedKeys([]);
            this._selectUser();
        }
    },
    props: {
        value: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        multiple: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        selectedArgs: {
            type: Object
        },
        separator: {
            type: String,
            default: ','
        }
    }
};
</script>

<style scoped>

</style>
